<template>
  <div class="container">
    <div v-if="accessToken === ''" class="row" style="padding-top: 20px; padding-bottom: 50px;">
      <div class="col-md-6">
        <el-alert
          :title="$t('header.login_tips.title')"
          type="error"
        >
          {{ $t('header.login_tips.content') }}
        </el-alert>
      </div>
      <div class="col-md-6">
        <el-card>
          <h2 style="text-align: center">{{ $t('header.login') }}</h2>
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginFormRules"
            label-width="100px"
          >
            <el-form-item :label="$t('header.login_form.username')" prop="username">
              <el-input v-model="loginForm.username" />
            </el-form-item>
            <el-form-item :label="$t('header.login_form.password')" prop="password">
              <el-input v-model="loginForm.password" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="doLogin">{{ $t('header.login_form.login') }}</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>
    <div v-else>
      <br>
      <div class="filter-container">
        <el-input
          v-model="listQuery.keyword"
          class="filter-item"
          style="width: 350px;"
          placeholder="搜索产品型号/项目类型/申请人"
          clearable
          @keyup.enter.native="doSearch"
        />&nbsp;&nbsp;&nbsp;
        <el-select
          v-model="listQuery.projectType"
          class="filter-item"
          filterable
          clearable
          placeholder="请选择项目类型"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>&nbsp;&nbsp;&nbsp;
        <el-select
          v-model="listQuery.projectStatus"
          class="filter-item"
          filterable
          clearable
          placeholder="请选择项目状态"
        >
          <el-option
            v-for="item in projectStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>&nbsp;&nbsp;&nbsp;
        <el-button
          v-waves
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          @click="doSearch"
        />
      </div>
      <el-table
        :key="tableKey"
        v-loading="loading"
        :data="list"
        border
        highlight-current-row
        style="width: 100%;"
        @sort-change="sortChange"
      >
        <el-table-column
          align="center"
          type="index"
          width="50"
        />
        <el-table-column
          :label="$t('body.project.model')"
          prop="model"
          width="200"
        />
        <el-table-column
          :label="$t('body.project.type')"
          prop="typeLabel"
          width="120"
        >
          <template slot-scope="{ row }">
            <template v-if="locale === 'en'">{{ row.typeLabelEn }}</template>
            <template v-else>{{ row.typeLabel }}</template>
          </template>
        </el-table-column>
        <!--
        <el-table-column
          :label="$t('body.project.owner')"
          prop="ownerName"
        />
        -->
        <el-table-column
          :label="$t('body.project.applicant')"
          prop="applicant"
          width="100"
        />
        <el-table-column
          :label="$t('body.project.acceptanceNo')"
          prop="acceptanceNumber"
          width="130"
        />
        <!--
        <el-table-column
          :label="$t('body.project.clientCode')"
          prop="clientCode"
        />
        -->
        <el-table-column
          :label="$t('body.project.status')"
          prop="statusLabel"
          width="110"
        >
          <template slot-scope="scope">
            <template v-if="locale === 'en'" type="success">{{ scope.row.statusLabelEn }}</template>
            <template v-else type="success">{{ scope.row.statusLabel }}</template>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('body.project.recordNo')"
          prop="recordNo"
          width="130"
        />
        <el-table-column
          :label="$t('body.project.issueDate')"
          prop="issueDate"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.issueDate | datetimeFilter }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('body.project.expireDate')"
          prop="expireDate"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.expireDate | datetimeFilter }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('body.project.sampleTrackingNo')"
          prop="sampleTrackingNo"
          width="160"
        />
        <el-table-column
          :label="$t('body.project.certificateTrackingNo')"
          prop="certificateTrackingNo"
          width="180"
        />
        <el-table-column
          :label="$t('body.project.paymentStatus')"
          prop="paymentStatus"
          width="130"
        >
          <template slot-scope="{ row }">
            <template v-if="locale === 'en'">{{ row.paymentStatusLabelEn }}</template>
            <template v-else>{{ row.paymentStatusLabel }}</template>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('body.project.createdDate')"
          prop="createdDate"
          width="140"
        >
          <template slot-scope="scope">
            {{ scope.row.createdDate | datetimeFilter(true) }}
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="pagination" />
    </div>
    <br>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import waves from '@/directive/waves' // waves directive
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from '@/components/Pagination'
import { searchProject, getCodes } from '@/api/csp'
export default {
  name: 'Project',
  components: { Pagination },
  directives: { waves, permission },
  data() {
    return {
      list: [],
      tableKey: 0,
      total: 0,
      loading: false,
      listQuery: {
        page: 1,
        limit: 5,
        status: 0,
        projectType: '',
        projectStatus: '',
        keyword: ''
      },
      typeOptions: [],
      projectStatusOptions: [],
      loginForm: {
        username: 'admin',
        password: '123456'
      }
    }
  },
  computed: {
    ...mapGetters([
      'locale',
      'lang',
      'name',
      'sidebar',
      'avatar',
      'device',
      'accessToken'
    ]),
    loginFormRules() {
      return {
        username: [
          { required: true, message: this.$t('header.login_form.rules.required_username'), trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('header.login_form.rules.required_password'), trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    if (this.accessToken !== '') {
      this.doSearch()
    }
    this.getTypeOptions()
    this.getProjectStatusOptions()
  },
  methods: {
    getTypeOptions() {
      getCodes({ type: 'PROJ_TYPE' }).then(resp => {
        if (resp.status) {
          this.typeOptions = resp.data
        }
      })
    },
    getProjectStatusOptions() {
      getCodes({ type: 'PROJ_STATUS' }).then(resp => {
        if (resp.status) {
          this.projectStatusOptions = resp.data
        }
      })
    },
    doLogin() {
      this.$refs.loginForm.validate((valid) => {
        this.loading = true
        if (valid) {
          this.$store.dispatch('user/login', this.loginForm)
            .then(() => {
              // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
              this.loading = false
              // this.$store.dispatch('user/getInfo')
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
    doSearch() {
      this.loading = true
      searchProject({
        keyword: this.listQuery.keyword,
        pageNum: this.listQuery.page,
        pageSize: this.listQuery.limit,
        type: this.listQuery.projectType,
        status: this.listQuery.projectStatus,
        sort: this.listQuery.sort
      }).then(resp => {
        this.list = resp.list
        this.total = resp.total
        this.loading = false
      })
    },
    pagination() {
      this.listQuery.status = this.status
      this.doSearch()
    },
    sortChange(data) {
      const { prop, order } = data
      if (prop === 'issueDate') {
        this.sortByIssueDate(order)
      } else if (prop === 'expireDate') {
        this.sortByExpireDate(order)
      } else if (prop === 'createdDate') {
        this.sortByCreatedDate(order)
      }
    },
    sortByIssueDate(order) {
      if (order === 'ascending') {
        this.listQuery.sort = '+issueDate'
      } else if (order === 'descending') {
        this.listQuery.sort = '-issueDate'
      }
    },
    sortByExpireDate(order) {
      if (order === 'ascending') {
        this.listQuery.sort = '+expireDate'
      } else if (order === 'descending') {
        this.listQuery.sort = '-expireDate'
      }
    },
    sortByCreatedDate(order) {
      if (order === 'ascending') {
        this.listQuery.sort = '+createdDate'
      } else if (order === 'descending') {
        this.listQuery.sort = '-createdDate'
      }
    }
  }
}
</script>

<style scoped>

</style>
